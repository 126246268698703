<template>
  <en-drawer :model-value="modelValue" title="联保申请" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :loading="form.loading">
      <en-form-item label="项目">
        <en-table :data="form.data.surfaces" class="w-full">
          <en-table-column label="项目" prop="surface.fullMessage"></en-table-column>
          <en-table-column label="原网点" prop="jointWarrantySurface.workOrder.tenant.shortName"></en-table-column>
          <en-table-column label="联保网点">
            <template #default>{{ form.data.tenant.shortName }}</template>
          </en-table-column>
        </en-table>
      </en-form-item>
      <en-form-item label="联保说明" prop="comment">
        <en-input :model-value="form.data.jointWarrantyApplicationComment" type="textarea" disabled></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <button-ajax :method="footer.cancel.click" :loading="form.loading">拒绝</button-ajax>
      <button-ajax :method="footer.confirm.click" :loading="form.loading">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    workorderId: Number,
    method: Function
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          if (this.workorderId) {
            const res = await this.ajax('GET /enospray/workorder/:workOrderId', { paths: [this.workorderId] })
            this.form.data.jointWarrantyApplicationComment = res.data[0].jointWarrantyApplicationComment ?? ''
            this.form.data.surfaces = res.data[0].surfaces.filter((item) => item.jointWarrantyApplicationStatus?.code === 'P')
            this.form.data.tenant.shortName = res.data[0].tenant?.shortName ?? ''
          }
        } else {
          this.form.data.jointWarrantyApplicationComment = ''
          this.form.data.surfaces = []
          this.form.data.tenant.shortName = ''
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          jointWarrantyApplicationComment: '',
          surfaces: [] as EnospraySprayDefinitions['WorkOrderSurfaceDto'][],
          tenant: {
            shortName: ''
          }
        },
        ajax: {
          submit: {
            action: 'POST /enospray/workorder/jointwarrantyaudit',
            loading: true,
            params(params) {
              params.payload.comment = ''
              params.payload.surfaces = this.form.data.surfaces
              params.payload.workOrderId = this.workorderId
            }
          },
          reject: {
            action: 'POST /enospray/workorder/jointwarrantyaudit',
            loading: true,
            params(params) {
              params.payload.surfaces = []
              params.payload.workOrderId = this.workorderId
            }
          }
        }
      },
      footer: {
        cancel: {
          async click() {
            await this.form.reject()
            this.emit('update:model-value', false)
            return this.method?.()
          }
        },
        confirm: {
          async click() {
            await this.form.submit()
            this.emit('update:model-value', false)
            return this.method?.()
          }
        }
      }
    }
  }
})
</script>
